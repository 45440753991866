@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;500;600;700&display=swap');
@import url('https://pro.fontawesome.com/releases/v5.15.4/css/all.css');
@import '~react-toastify/dist/ReactToastify.css';

$primary-color: #3b82f6;
$success-color: #10b981;
$warning-color: #f59e0b;
$error-color: #ef4444;
$light-primary: #eef4ff;
$grey-text: #9ca3af;
$dark: #374151;

@import 'node_modules/spectre.css/src/spectre';
@import 'node_modules/spectre.css/src/spectre-exp';

* {
    box-shadow: none !important;
}
body {
    color: $dark;
    font-family: 'IBM Plex Sans Thai', sans-serif;
}
.form-input,
.form-select {
    border-color: #f1f1f1;

    &:disabled {
        color: #999;
        background-color: #f1f1f1;
        cursor: not-allowed;

        &:focus {
            border-color: #bcc3ce;
        }
    }
}
.form-floating-label {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0.75rem;

    span {
        display: none;
        position: absolute;
        top: 0.3rem;
        left: 0.5rem;
        font-size: 0.5rem;
        color: $grey-text;

        &.active {
            display: block;
        }
    }
    input,
    select {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 0.2rem;
        height: 50px;
        padding: 0 0.5rem;
        outline: none;
        font-size: 0.7rem;
        color: darken($grey-text, 25%);
        appearance: none;
        transition: all 0.1s ease;

        &:focus {
            border-color: $primary-color;
        }
        &:disabled {
            background-color: #eee;
        }
        &.active {
            padding-top: 0.75rem;
        }
    }
}
.form-label {
    font-size: 0.8rem;
}
.form-group {
    margin-bottom: 0.5rem !important;
}

.latest-billing-update {
    margin-top: 24px;
}

.empty-history-billing {
    width: 100%;
    height: 100%;
    padding: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.form-action {
    margin-top: 0.75rem;
}
.text-white {
    color: #fff;
}
.toast {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
    margin: 0 0 0.5rem 0 !important;

    &.text-center {
        justify-content: center;
    }

    i {
        font-size: 1rem;
        margin-right: 0.8rem;

        &.fa-info-circle,
        &.fa-check-circle {
            margin-top: 2px;
        }
    }
}

.Toastify__toast-body {
    font-family: 'Sarabun', sans-serif;
}
.Toastify__toast--success {
    background: $success-color !important;
}
.Toastify__toast--error {
    background: $error-color !important;
}
.section-heading {
    margin-bottom: 1rem;

    i {
        margin-bottom: 1rem;
    }
    h3 {
        font-size: 1.2rem;
        margin-bottom: 0.25rem;
    }
    h6 {
        font-size: 0.6rem;
    }
}
.auth-body {
    display: flex;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #021048, #1e38a3);
}
.auth-section {
    max-width: 22.5rem;

    .btn {
        min-width: 5rem;
    }
}
.btn.min-w {
    min-width: 5rem;
}
.divider[data-content] {
    margin: 1.5rem 0;
}
.card {
    border-radius: 0.5rem !important;

    .card-body {
        padding: 1rem !important;
    }
}
.off-canvas {
    .off-canvas-toggle {
        position: sticky;
        font-size: 1rem;
    }
    .off-canvas-sidebar {
        width: 14rem;
        // background-color: #2d3436;
        // background: linear-gradient(145deg, #021048, #1e38a3);
    }
    .off-canvas-content {
        width: 100% !important;
        padding: 0;
    }
}
.block {
    margin: 0 auto;
    max-width: 45rem;
    position: relative;
}
.block-my-drive {
    margin: 0 auto;
    max-width: 55rem;
    position: relative;
}
.header-with-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 1.5rem 0;
    border-bottom: 1px solid #f3f4f6;

    h2 {
        font-size: 2rem;
    }
    h6 {
        margin-top: 0.25rem;
    }
    // .btn {
    //     border-radius: 100%;
    // }
}
.xver-content {
    padding: 1.5rem 0;
}
.announcement {
    padding: 1rem 2rem;
}

.btn-billing-form {
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    width: auto;
    border-radius: 6px;
}

.btn-light-primary {
    background-color: $light-primary;
    border-color: transparent !important;
    color: darken($primary-color, 10%) !important;

    &:hover {
        background-color: darken($light-primary, 5%);
    }
}
.btn-warning {
    background-color: $warning-color;
    border-color: transparent !important;
    color: #fff;

    &.loading {
        &::after {
            border-bottom-color: #f3f4f6;
            border-left-color: #f3f4f6;
        }
    }

    &:hover,
    &:focus {
        background-color: darken($warning-color, 5%);
    }
}
.btn-default {
    background-color: #ececec;
    border-color: transparent !important;
    color: $dark !important;

    &:hover {
        background-color: darken(#ececec, 5%);
    }
}
.btn-lg {
    height: 2.5rem !important;
    padding: 0.5rem 1rem !important;
    line-height: 1.5;
}
.section-header {
    margin-bottom: 1rem;

    h4 {
        font-weight: 600;
        font-size: 1.4rem;
    }
    h6 {
        font-size: 0.7rem;
    }

    &:not(:first-child) {
        margin-top: 1.5rem;
    }
}
.mx-section {
    margin: 1.5rem 0;
}
// thead {
//     color: #fff;
//     background-color: #2d3436;
// }

.modal-container {
    padding: 1.5rem !important;
    border: 1px solid #bcc3ce;
}

.modal-sm {
    .modal-container {
        max-width: 420px !important;
        padding: 1rem !important;
    }
    .modal-body {
        padding: 0.5rem 0 !important;
    }
    .modal-footer {
        margin-top: 0.5rem;
        padding: 0;
    }
}

.billing-text-detail {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.body-margin-top-form {
    margin-top: 20px;
}

.width-95 {
    width: 95%;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-confirm {
    .modal-container {
        padding: 1.5rem !important;
        border: 1px solid #bcc3ce !important;
        max-width: 20rem !important;
    }
    .content {
        overflow: none;
    }
    .confirm {
        display: flex;
        margin-top: 1rem;

        button {
            flex-grow: 1;
            height: 2rem;
            font-size: 0.8rem;
        }
    }
}
.modal-header {
    padding: 0 0 0.5rem 0 !important;
}
.modal-body {
    padding: 0 !important;

    .content {
        font-weight: lighter;
    }
}
.modal-overlay {
    background: rgba(0, 0, 0, 0.5) !important;
}
.modal-footer {
    display: flex;
    justify-content: space-between;

    button {
        width: 49%;
    }
}
.text-helper {
    color: $grey-text;
    margin-bottom: 0.25rem !important;
}
.btn-clear {
    text-align: right;
}
.w-100 {
    width: 100%;
}
.mr-05 {
    margin-right: 0.5rem;
}
.ml-05 {
    margin-left: 0.5rem;
}
.mt-025 {
    margin-top: 0.25rem !important;
}
.mt-05 {
    margin-top: 0.5rem !important;
}
.mt-075 {
    margin-top: 0.75rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-1-5 {
    margin-top: 1.5rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mt-3 {
    margin-top: 3rem !important;
}
.mt--1 {
    margin-top: -1px;
}
.mt-5rem {
    margin-top: 5rem;
}
.br-0 {
    border-radius: 0;
}
.font-normal {
    font-weight: 300;
}
.font-bold {
    font-weight: bold;
}
.flex-center {
    justify-content: center;
}

.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.xver-loading-splash {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.xver-overlay.active {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    width: 100%;
    height: 100%;
}
.xver-container {
    min-height: 100vh;
}

.xver-btn-bin {
    padding: 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 17px;
    border-radius: 6px;
    border-color: #f87171;
    background-color: #ef4444;
    color: #ffffff;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    cursor: pointer;

    &:hover {
        background-color: #dc2626;
    }
}

.xver-btn {
    padding: 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 17px;
    border-radius: 6px;
    border-color: #2c79f5;
    background-color: #3b82f6;
    color: #ffffff;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    cursor: pointer;

    &:hover {
        background-color: rgb(21, 81, 176);
    }
}

.xver-btn-wrap {
    width: 100%;
    margin-left: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 210px;
}

.xver-verification-box {
    padding: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    border: 1px solid #e4e7eb;
    border-radius: 10px;
    background-color: transparent;
    color: #ffffff;
}

.xver-verification-header {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #3b82f6;
}

.xver-navbar-burger {
    display: flex;
    height: auto;
    padding: 0.6rem 1.15rem;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
}

.xver-navbar {
    display: flex;
    height: auto;
    padding: 0 1.15rem;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.xver-sidebar {
    background-color: #191919 !important;
    // background: linear-gradient(135deg, #021048, #1e38a3) !important;

    .xver-brand {
        cursor: text;
        user-select: text;
        color: #fff;
        margin-top: 1.25rem;

        .xver-logo {
            border-radius: 0.1rem;
            display: inline-flex;
            align-items: center;

            h2 {
                display: inline-block;
                font-size: 1rem;
                font-weight: 800;
                line-height: 1.5rem;
                margin: 0 0.3rem 0 0.5rem;
            }
        }

        .xver-balance {
            cursor: text;
            user-select: text;
            margin: 1rem 0;

            h6 {
                font-size: 0.6rem;
            }
            .balance-currency {
                font-size: small;
                margin-left: 4px;
            }
        }
    }

    .xver-nav {
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        margin: 1rem 0rem;

        ul {
            list-style: none;
            margin: 0;
            width: 100%;

            li {
                margin: 0;

                a {
                    display: flex;
                    text-overflow: ellipsis;
                    color: #fff;
                    padding: 0.65rem 1.5rem;
                    font-size: 1rem;
                    text-decoration: none;

                    &:hover {
                        background: rgba(255, 255, 255, 0.1);
                    }
                    &.active {
                        background: rgba(255, 255, 255, 0.2);

                        h6 {
                            font-weight: 600;
                        }
                    }

                    div {
                        align-items: center;
                    }
                    h6 {
                        margin-bottom: 0px;
                    }
                    i {
                        // margin-top: 0.13rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}
.xver-main-content {
    flex: 1 1 auto;
    width: 100%;
}
.xver-loading {
    height: 2rem;

    &::after {
        height: 2rem;
        width: 2rem;

        border: 0.2rem solid $primary-color;
        border-right-color: transparent;
    }
}
.xver-table {
    thead {
        background-color: $dark;
    }
}
.xver-box {
    border-radius: 0.25rem;
    border: 1px solid #f3f4f6;
    margin-bottom: 1rem;

    .box-header {
        padding: 1rem 1.25rem;
        border-bottom: 1px solid darken(#f3f4f6, 5%);
    }
    .box-content {
        padding: 1rem 1.25rem;
    }
    .box-inner-header {
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #f3f4f6;

        h5 {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        h6 {
            font-size: 0.6rem;
        }
    }
    h2 {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.25rem;
    }
    h6 {
        font-size: 0.6rem;
        margin-bottom: 0;
    }
}
.instance-image {
    display: flex;
    align-items: center;

    h5 {
        font-size: 0.8rem;
    }
    h6 {
        font-size: 0.6rem;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        font-size: 1.5rem;
        border: 0.15rem solid;
        border-radius: 50%;
        margin-right: 0.75rem;
        width: 50px;
        height: 50px;

        &.online {
            border-color: $success-color;
        }
        &.offline {
            border-color: $error-color;
        }
        &.warning {
            border-color: $warning-color;
        }
    }
}
.swal2-popup {
    width: 28rem;
}
.form-topup-terms {
    label {
        font-size: 0.7rem;
    }
}
.xver-tab-menu {
    display: flex;
    padding: 1rem;
    background-color: #f1f1f1;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    align-items: center;
    transition: ease all 0.1s;
    cursor: pointer;

    i {
        font-size: 1rem;
        padding: 0.75rem;
        color: $dark;
        background-color: #fff;
        border-radius: 100%;
        margin-right: 1rem;
    }
    h1 {
        font-size: 0.8rem;
        margin-bottom: 0.25rem;
        font-weight: bold;
    }
    h6 {
        font-size: 0.6rem;
        margin-bottom: 0;
    }

    &.active,
    &:hover {
        color: $primary-color;
        background-color: #f3f4f6;

        i {
            color: $primary-color;
            background-color: #fff;
        }
    }
}
.xver-payment-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 60px);

    i {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    h1 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }
    h6 {
        font-size: 0.8rem;
    }

    &.waiting i {
        color: $primary-color;
    }
    &.complete i {
        color: $success-color;
    }
    &.error i {
        color: $error-color;
    }
}
.promptpay-box {
    margin-top: 1rem;

    h6 {
        font-size: 0.6rem;
        margin-top: 0.25rem;
        color: $warning-color;
    }
    img {
        width: 200px;
        border: 1px solid #bcc3ce;
        border-radius: 0.25rem;
    }
}
.auth-submit-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 960px) {
    .header-with-element {
        padding: 1.5rem 1rem;
        border-bottom: 1px solid #f3f4f6;

        h2 {
            font-size: 1.5rem;
        }
    }
    .btn-action {
        margin: 0;
    }
    .xver-content {
        padding: 1.5rem 1rem;
    }

    .xver-verification-box {
        flex-direction: column;
    }

    .xver-verification-header {
        font-size: 22px;
    }

    .xver-btn-wrap {
        margin-left: 0;
        margin-top: 20px;
        max-width: 100%;
    }

    .xver-navbar-burger {
        border: 1px solid #eef4ff;
    }
}
.auth-ext {
    color: #fff;
    text-align: center;
    margin-top: 1rem;
}
@media screen and (min-width: 480px) and (max-width: 1280px) {
    .header-with-element {
        padding: 1rem 2rem;
    }
    .xver-content {
        padding: 1.5rem 2rem;
    }
}

@keyframes xver-sidebar-slide-in-left {
    0% {
        left: -20%;
    }
    100% {
        left: 0%;
    }
}

.xver-tabs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(1.5rem - 0.25rem);
}
.xver-tab {
    display: flex;
    border-radius: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    border: 0.1rem solid #f3f4f6;
    transition: 0.2s ease all;
    cursor: pointer;
    width: 5rem;
    height: 4rem;

    i {
        font-size: 1.2rem;
    }
    span {
        font-size: 0.7rem;
        font-weight: bold;
        margin-top: 0.5rem;
    }

    &:hover {
        border-color: darken(#f3f4f6, 10%);
    }
    &.active {
        color: $primary-color;
        border-color: $primary-color;
        background-color: lighten($primary-color, 38%);
    }
}

@media screen and (max-width: 960px) {
    .account-tab {
        width: calc(33.33% - 0.25rem);
    }
}

// LAUNCH
.xver-os-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.xver-os {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: calc(20% - 0.5rem);
    border: 1px solid #f3f4f6;
    border-radius: 0.25rem;
    cursor: pointer;

    .os-content {
        padding: 0.75rem;

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
        }
        img {
            height: 50px;
            filter: grayscale(100%);
        }
        h6 {
            font-size: 0.8rem;
            margin-top: 0.6rem;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
    .os-footer {
        width: 100%;
        border-top: 1px solid #f3f4f6;

        select {
            border: none;
            padding: 0 0.75rem;
            font-size: 0.75rem;
        }
    }

    &:hover {
        border-color: darken(#f3f4f6, 15%);

        .os-footer {
            border-color: darken(#f3f4f6, 15%);
        }
    }
    &.active {
        color: $primary-color;
        border-color: $primary-color;
        background-color: lighten($primary-color, 40%);

        .os-content {
            img {
                filter: grayscale(0);
            }
        }
        .os-footer {
            border-color: $primary-color;

            select {
                font-weight: bold;
            }
        }
    }
}
.windows-license-warning {
    color: $warning-color;
    padding-top: 0.5rem;
}

.xver-package-wrapper {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.xver-dedicated-package-wrapper {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.xver-package {
    display: flex;
    position: relative;
    padding: 1rem;
    font-size: 0.75rem;
    border: 1px solid #f3f4f6;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    transition: ease all 0.1s;
    cursor: pointer;

    &:hover {
        border-color: darken(#f3f4f6, 15%);
    }
    &.active {
        color: $primary-color;
        border-color: $primary-color;
        background-color: lighten($primary-color, 40%);

        .xver-recommended {
            background-color: $primary-color;
        }
    }
    &.recommended {
        border-color: $error-color;
    }

    b {
        margin: 0 0.25rem;
    }
    span:not(:first-child) {
        margin-top: 0.1rem;
    }
    .xver-package-price {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 0.5rem;
        text-align: center;
        flex-wrap: wrap;

        div {
            small {
                font-size: 0.6rem;
            }
            span {
                font-weight: bold;
                font-size: 0.8rem;
                display: block;
            }
        }
    }
    .xver-recommended {
        position: absolute;
        top: -0.6rem;
        color: #fff;
        background-color: $error-color;
        padding: 0.1rem 0.5rem;
        border-radius: 1rem;
    }
}
.startup-script-section {
    margin-bottom: 1rem;
}

// ACCOUNT
.xver-ssh-keys-wrapper {
    margin-bottom: 1rem;
}
.xver-ssh-key-item {
    display: flex;
    color: $dark;
    border: 1px solid #f3f4f6;
    margin-bottom: 0.5rem;
    padding: 1rem;
    border-radius: 0.25rem;
    justify-content: space-between;
    align-items: center;

    h5 {
        font-weight: bold;
        margin-bottom: 0.25rem;
    }
    h6 {
        font-size: 0.5rem;
        margin-bottom: 0;
    }
    button {
        width: 40px;
        height: 40px;
        border-radius: 100%;
    }

    &.text-center {
        justify-content: center;
    }
}

@keyframes rt {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// INSTANCES
.xver-insance-list-wrapper {
    margin-bottom: 0.5rem;
}
.xver-instance-list-item {
    display: flex;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e4e7eb;
    color: $dark;

    .xver-instance-info {
        display: flex;
        align-items: center;

        .instance-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: 1rem;

            .instance-icon-spinner {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                border: 0.2rem solid $dark;
                position: relative;
                z-index: 1;

                img {
                    height: 30px;
                }

                &.running {
                    border-color: $success-color;
                }
                &.install {
                    border-color: $primary-color;

                    &:before {
                        content: '';
                        width: 3rem;
                        height: 3rem;
                        border-radius: 100%;
                        border-top: 0.2rem solid #ccc;
                        border-right: 0.2rem solid transparent;
                        border-bottom: 0.2rem solid transparent;
                        border-left: 0.2rem solid transparent;
                        position: absolute;
                        z-index: 9;
                        animation: rt 1s infinite;
                    }
                }
                &.stopped {
                    border-color: $error-color;
                }
                &.destroying {
                    border-color: $error-color;

                    &:before {
                        content: '';
                        width: 3rem;
                        height: 3rem;
                        border-radius: 100%;
                        border-top: 0.2rem solid #ccc;
                        border-right: 0.2rem solid transparent;
                        border-bottom: 0.2rem solid transparent;
                        border-left: 0.2rem solid transparent;
                        position: absolute;
                        z-index: 9;
                        animation: rt 1s infinite;
                    }
                }
            }
        }

        h3 {
            font-size: 0.8rem;
            margin-bottom: 0;
        }
        h6 {
            font-size: 0.6rem;
            margin-top: 0.3rem;
            margin-bottom: 0;
        }
        label {
            margin-top: 0.75rem;
        }
    }
    .xver-instance-action {
        .btn {
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}
.xver-instance-meter {
    padding: 1rem;
    border: 1px solid #f3f4f6;
    border-radius: 6px;
    margin-bottom: 1.5rem;

    h5 {
        font-size: 0.8rem;
        font-weight: bold;
    }
    h6 {
        color: $primary-color;
        font-size: 1.6rem;
        margin-bottom: 0;
        text-align: left;

        span {
            color: $dark;
            font-size: 0.6rem;
        }
    }
}
.xver-instance-info-row {
    display: flex;
    min-height: 1.75rem;
    margin-bottom: 0.25rem;

    .info-title {
        font-weight: bold;
        width: 6rem;
    }
}
.xver-instance-info-bandwidth {
    min-height: 1.75rem;
    margin-bottom: 0.25rem;

    .info-title {
        font-weight: bold;
        width: 6rem;
    }
    .info-desc {
        font-size: 0.6rem;
        text-align: right;
    }
}
.btn-instance-action {
    width: 100%;
    margin-bottom: 0.75rem;
}
.quota-box {
    h5 {
        font-weight: bold;
    }
}

.notification-box {
    h5 {
        font-weight: bold;
    }
}

.quota-item {
    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    .quota-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.7rem;
    }
}

.no-instance {
    span {
        margin-bottom: 1rem;
    }
    h2 {
        margin-bottom: 0.4rem;
    }
    h6 {
        font-size: 0.8rem;
    }
    .btn {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 960px) {
    .xver-os {
        width: calc(50% - 0.5rem);
    }
    .xver-package-wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .instance-action-groups {
        margin-top: 1.5rem;
    }
}

// KYC
.form-kyc-info {
    text-align: center;
    font-size: 0.6rem;
    padding: 1rem 0 0.5rem 0;
}
.form-kyc-terms label {
    font-size: 0.7rem;
}
.kyc-id-card-drop {
    position: relative;
    border: 2px dashed #eee;
    border-radius: 0.2rem;
    cursor: pointer;

    h3 {
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }
    h6 {
        font-size: 0.6rem;
        margin-bottom: 0;
    }

    .kyc-id-card-drop-content {
        padding: 2rem 1rem;
        text-align: center;
        color: $grey-text;
    }
    .kyc-id-card-drop-overlay {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: rgba(255, 255, 255, 0.95);
        width: 100%;
        height: 100%;
        border-radius: 0.2rem;
        color: $dark;

        span {
            padding-top: 0.5rem;
        }
    }
}
.kyc-id-card-info {
    font-size: 0.6rem;
    margin-bottom: 0.5rem;
}
.kyc-status {
    margin-top: 1rem;
}

.billing-history {
    .column {
        padding: 0.4rem;
    }
}

.billing-form {
    .column {
        padding: 0.4rem;
    }
}

.pagination {
    @extend .pagination;
    justify-content: center;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-none {
    overflow: none;
}

.maintenance-container {
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

.maintenance-cube {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.maintenance-cube > * + * {
    margin-top: 1.5rem;
}

.maintenance-header-h1 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: #202020;
    text-align: center;
}

.maintenance-subtitle-header {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 100;
    color: #393939;
}

.my-drive-title {
    background: unset !important;
    td {
        border: unset !important;
    }
}
.my-drive-item-icon {
    color: $primary-color;
}

.my-drive-item:hover {
    cursor: pointer;
    color: $primary-color;
}

.copy-button {
    margin: 0;
    padding: 0;
    border: none;
    height: auto;
    width: auto;
    background: none;
    cursor: pointer;
    font-size: 15px;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    &:hover {
        color: #2c79f5 !important;
    }
}

.copy-button-text {
    cursor: pointer;
}

.copy-button-icon {
    color: #3b82f6;
    cursor: pointer;
}

.promptpay-modal {
    .modal-header {
        .h5 {
            font-size: 1rem;
        }
        h6 {
            font-weight: normal;
            font-size: 0.6rem;
        }
    }

    .modal-body {
        text-align: center;

        img {
            width: 10rem;
            border-radius: 0.25rem;
            border: 1px solid #d1d5db;
        }
    }
}
.promptpay-complete-modal {
    .modal-body {
        text-align: center;

        i {
            font-size: 2.5rem;
            color: $success-color;
        }
        h5 {
            margin-top: 1rem;
        }
        h6 {
            font-weight: normal;
            font-size: 0.8rem;
        }
    }
}

.instance-stopped-warning {
    margin-bottom: 1.25rem;
}

.topright {
    position: absolute;
    top: 8px;
    right: 16px;
    font-size: 24px;
    background-color: red;
    color: white;
    padding: 5px;
}
